import { useEffect, useState } from "react";
import MainLayout from "../../Layout/MainLayout";
import axios from "axios";
import BASEURL from "../../Config/global";
import { Button, Modal, Tab, Tabs } from "react-bootstrap";
import "./style.css";
import BASEURLFrontend from "../../Config/urls";
import { ShareToSocialPlatform } from "../../Components/UnCommon";
import { crossIcon } from "../../Assets/svg";
import { saveAs } from 'file-saver';

const DownloadAssets = () => {

  const [assetsData, setAssetsData] = useState({});
  const [loader, setLoader] = useState(true);
  const [loading, setLoading] = useState(false);
  const [shareEarnModal, setShareEarnModal] = useState(false);
  const [platformUrl, setPlatformUrl] = useState('');
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    setLoader(true);
    getDataByApi();
  }, []);

  const getDataByApi = async () => {
    try {
      setLoader(true);
      const response = await axios.get(`${BASEURL}/api/user/upload-assets/${user.id}`);
      if (response.data.error != true) {
        // console.log(response?.data?.data)
        setAssetsData(response?.data?.data?.[0]);
        setLoader(false);
      } else {
        console.log(response.data.message);
        setLoader(false);
      }
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };


  const handleDownload = async (imageUrl, imageTitle) => {
    try {
      setLoading(true);
      const response = await axios({
        url: imageUrl,
        method: 'GET',
        responseType: 'blob', // Important for handling binary data
      });

      if (response.data) {
        debugger
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', imageTitle + '.png'); // Specify the filename
        document.body.appendChild(link);
        link.click();

        // Clean up by removing the link and revoking the object URL
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        setLoading(false);
      } else {
        setLoading(false);
      }

    } catch (error) {
      console.error('Error downloading the image', error);
      setLoading(false);
    }
  };


  const downloadImage = (imageURL) => {
    // URL of the image you want to download
    // const imageURL = 'https://storage.googleapis.com/relax_dev/uploads/uploaded_file_MIkGljM.png';
    saveAs(imageURL, 'downloaded_image.png'); // Specify the filename
  };


  return (
    <>
      <MainLayout title="Download or Share" arrow navigate={"share-earn"}>
        {
          loader ? (
            <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: 400 }}>
              <div class="spinner-border spinner-border-lg text-white" role="status"></div>
            </div>
          ) : (
            <div className="row align-items-center mt-4">
              <div className="col-12">
                <div className='general-heading-section text-center'>
                  <h2 className="mb-3">{assetsData.title}</h2>
                  <h4 className="mb-4">{assetsData.description}</h4>
                </div >
                <div className="d-flex flex-column position-relative">
                  {assetsData?.upload_asset_coupon_detail?.length > 0 && (
                    <Tabs
                      defaultActiveKey="1"
                      id="uncontrolled-tab-example"
                      className="download-assets-tabs"
                    >
                      {assetsData?.upload_asset_coupon_detail.map((platform, platform_index) => (
                        <Tab key={platform_index} className="download-assets-tab" eventKey={platform_index + 1} title={<div className="title-bar"><i><img src={platform?.platform_icon} alt="Image" /></i><span>{platform?.platform_title}</span></div>}>
                          {
                            platform?.platform_detail?.length > 0 && platform.platform_detail.map((child_item, child_index) => (
                              <div className="container-fluid px-xl-5 mb-5" key={child_index}>
                                <div className="row">
                                  <div className="col-lg-6 mt-5">
                                    <div className="pe-xxl-5">
                                      <small className="fw-light">Asset Image</small><br />
                                      <span className="size-text"><b>Size: </b>{child_item?.size}px</span>
                                      <div className="display-image mt-4">
                                        <img src={child_item?.file_upload} alt="Image" className="img-fluid" />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 mt-5">
                                    <p className="description-text mb-4 mb-sm-5">
                                      <b>Description: </b>
                                      {child_item?.description}
                                    </p>
                                    <p className="description-text mb-0">
                                      <b>Instructions: </b>
                                    </p>
                                    <div className="instruction-list" dangerouslySetInnerHTML={{ __html: child_item?.instruction }}></div>
                                    {/* {child_item?.instruction?.innerHTML} */}
                                    <div className="action-buttons d-flex flex-column flex-sm-row gap-4">
                                      <Button onClick={() => { !loading && downloadImage(child_item?.file_upload, child_item?.title) }} className="download-btn">
                                        {
                                          loading ? (
                                            <div class="spinner-border spinner-border-sm text-white" role="status"></div>
                                          ) : (
                                            <svg width="22" height="27" viewBox="0 0 26 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M24.1327 25.6432H1.85349C1.10916 25.6432 0.499939 26.252 0.499939 26.9968C0.499939 27.7411 1.10916 28.3503 1.85349 28.3503H24.1464C24.8907 28.3503 25.4999 27.7411 25.4999 26.9968C25.4999 26.252 24.8907 25.6432 24.1327 25.6432Z" fill="white" />
                                              <path d="M12.0048 20.784C12.2622 21.0546 12.614 21.217 12.9928 21.217C13.372 21.217 13.7239 21.0546 13.9808 20.784L21.9127 12.2702C22.427 11.7285 22.3864 10.8624 21.8452 10.3617C21.3035 9.84743 20.4373 9.88761 19.9367 10.4293L14.3464 16.4253V1.64491C14.3464 0.900164 13.7372 0.291367 12.9928 0.291367C12.2485 0.291367 11.6393 0.900164 11.6393 1.64491V16.4253L6.06263 10.4293C5.54832 9.88804 4.69541 9.84743 4.15416 10.3617C3.61291 10.8761 3.5723 11.729 4.08661 12.2702L12.0048 20.784Z" fill="white" />
                                            </svg>
                                          )
                                        }
                                        Download
                                      </Button>
                                      <Button onClick={() => { setShareEarnModal(true); setPlatformUrl(platform?.platform_url) }} className="download-btn">
                                        <svg width="22" height="27" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M14.8613 21.6976C6.62967 21.2689 0.00976562 14.4365 0.00976562 6.10048V0.291382L2.08816 5.12992C4.54628 10.0453 9.42335 13.1882 14.8613 13.4771V6.82859L28.0495 17.6091L14.8613 28.3504V21.6976Z" fill="white" />
                                        </svg>
                                        Share
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          }
                        </Tab>
                      ))}
                    </Tabs>
                  )}
                </div>
              </div >
            </div >
          )
        }
      </MainLayout >

      {/* Invite Share & Earn Modal */}
      <Modal
        show={shareEarnModal}
        centered
        className="soundModal mixer-modal"
        backdrop="static"
      >
        <Modal.Body>
          <div className="modal-wrapper d-flex flex-column">
            <div className="save-mix-header text-end">
              <button
                className="notButton ms-auto d-flex align-items-center p-4"
                onClick={() => setShareEarnModal(false)}

              >
                <img src={crossIcon} alt="Close Button" className="img-fluid" style={{ width: '15px' }} />
              </button>
            </div>
            <div className="d-flex flex-column justify-content-between flex-grow-1">
              <div className="p-4">
                <ShareToSocialPlatform url={platformUrl} showPlatforms={false} />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DownloadAssets;