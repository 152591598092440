import { useState } from "react";
import CustomButton from "../CustomButton";
import axios from "axios";
import Loader from "../Loader";
import CustomInput from "../CustomInput";
import DateField from "../DateField";
import BASEURL from "../../Config/global";
import BASEURLFrontend from "../../Config/urls";
import { Modal } from "react-bootstrap";
import { WhatsappIcon, WhatsappShareButton } from "react-share";
import { SaveShareMixLinkIcon, SaveShareMixWhatsappIcon } from "../../Assets/svg";
import { Link } from "react-router-dom";
import "./style.css";


const DescriptionEnjoyAndEarn = ({ stepBack, stepNext }) => {

    return (
        <>
            <div className="earn-description-wrapper p-2 p-lg-5 pb-lg-0">

                <div className="text-center mt-4 mt-lg-0">
                    <h4>Earn by Sharing Our Relaxation Secret!</h4>
                </div>

                <div className="text-start w-100 earn-points my-5" style={{ maxWidth: '350px' }}>
                    <p className="mb-0">Enjoy our relaxation and sleep tips?</p>
                    <p className="mb-0">Share them with your friends and make money!</p>
                    <p className="my-4">Just use your special referral link to tell others about our website</p>
                    <p className="mb-0">For every new visitor who signs up through your link, you'll earn a commission</p>
                    <p className="mb-0">It’s simple: Spread the word, help others relax, and get rewarded!</p>
                </div>

                <div className="d-lg-flex align-items-center footer">
                    <div style={{ flex: '0 0 350px' }}>
                        <p>Start sharing your link now and see how much you can earn</p>
                    </div>
                    <div className="flex-grow-1 text-lg-center continue-btn ps-lg-5 ms-lg-2 mb-4 mb-lg-0">
                        <CustomButton
                            variant="primaryButton"
                            text="Continue"
                            type="button"
                            onClick={stepNext}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

const LegalForm = ({ stepBack, stepNext, submit }) => {

    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(false);
    const user = JSON.parse(localStorage.getItem("user"));

    // Function to format the phone number
    const formatPhoneNumber = (value) => {
        if (!value) return value;

        // Remove all non-digit characters
        const phoneNumber = value.replace(/[^\d]/g, "");

        // Format the phone number
        const phoneNumberLength = phoneNumber.length;
        if (phoneNumberLength < 4) return phoneNumber;
        if (phoneNumberLength < 7) {
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === 'legal_phone_number') {
            const phoneNumber = formatPhoneNumber(value);
            setFormData({ ...formData, [name]: phoneNumber });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const submitLegalForm = async (e) => {
        try {
            e.preventDefault();
            setLoading(true);
            const date = formData.legal_date_of_birth.split('/')[2] + '-' + formData.legal_date_of_birth.split('/')[1] + '-' + formData.legal_date_of_birth.split('/')[0]
            const obj = {
                legal_first_name: formData.legal_first_name,
                legal_last_name: formData.legal_last_name,
                legal_date_of_birth: formData.legal_date_of_birth,
                legal_phone_number: formData.legal_phone_number,
                legal_email: formData.legal_email,
                legal_address: formData.legal_address,
            }
            const response = await axios.post(`${BASEURL}/api/user/legal_details_and_propagation_code/${user.id}/`, obj);
            if (!response.data.error) {
                setLoading(false);
                submit();
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }

    }

    return (
        <>
            <div className="p-lg-5 p-2 legal-form-area">
                <h5 className="text-center fs-3 pb-lg-3 mt-4 mt-lg-0">Financial Profile</h5>
                <form onSubmit={submitLegalForm} className="w-100 mt-5">
                    <div className="row">
                        <div className="col-lg-4">
                            <CustomInput
                                label="First Name"
                                required
                                id="fname"
                                type="text"
                                labelClass="mainLabel"
                                inputClass="mainInput"
                                name="legal_first_name"
                                value={formData.legal_first_name || ""}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-lg-4">
                            <CustomInput
                                label="Last Name"
                                required
                                id="lname"
                                type="text"
                                labelClass="mainLabel"
                                inputClass="mainInput"
                                name="legal_last_name"
                                value={formData.legal_last_name || ""}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-lg-4">
                            <DateField
                                label={'Birthdate'}
                                formValue={formData.legal_date_of_birth || ""}
                                selectedValue={(value) => {
                                    setFormData({ ...formData, ['legal_date_of_birth']: value })
                                }}
                            />
                        </div>
                        <div className="col-lg-6">
                            <CustomInput
                                label="Email"
                                required
                                id="email"
                                type="email"
                                labelClass="mainLabel"
                                inputClass="mainInput"
                                name="legal_email"
                                value={formData.legal_email || ""}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-lg-6">
                            <CustomInput
                                label="Phone No"
                                required
                                id="phone_no"
                                type="text"
                                labelClass="mainLabel"
                                inputClass="mainInput"
                                name="legal_phone_number"
                                value={formData.legal_phone_number || ""}
                                onChange={handleChange}
                                maxLength={14}
                            />
                        </div>
                        <div className="col-lg-12">
                            <CustomInput
                                label="Address"
                                required
                                id="address"
                                type="text"
                                labelClass="mainLabel"
                                inputClass="mainInput"
                                name="legal_address"
                                value={formData.legal_address || ""}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-lg-12 mt-4">
                            <div className="d-flex justify-content-center">
                                <div>
                                    {loading ? <Loader /> : <CustomButton variant="primaryButton" text="Submit" />}
                                </div>
                            </div>
                        </div>

                    </div>
                </form>
            </div>
        </>
    )
}


const ShareToSocialPlatform = ({ url, showPlatforms }) => {

    const [copiedText, setCopiedText] = useState(false);
    const shareUrl = url;

    const copyURL = () => {
        navigator.clipboard.writeText(url);
        setCopiedText(true);
        setTimeout(() => {
            setCopiedText(false);
        }, 1000);
    }

    return (
        <>

            <div className="flex-shrink-0 mb-4">
                <h5 className="text-center fs-3">Share & Earn</h5>
            </div>

            <div className="save-share-icon-wrapper flex-shrink-0 mb-5">
                <div className="text-center">
                    <Link onClick={copyURL} to={""} className="text-decoration-none" style={{ wordBreak: 'break-all' }}>
                        <span>{shareUrl}</span>

                        <svg style={{ position: 'relative', top: '-4px' }} width={25} className="ps-2" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="clone" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path fill="currentColor" d="M288 448H64V224h64V160H64c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H288c35.3 0 64-28.7 64-64V384H288v64zm-64-96H448c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H224c-35.3 0-64 28.7-64 64V288c0 35.3 28.7 64 64 64z"></path>
                        </svg>
                    </Link>
                </div>
                {showPlatforms && (
                    <div className="d-flex align-items-center justify-content-center mt-3">
                        <div className="whatsapp-icon position-relative mx-1">
                            <WhatsappShareButton
                                url={shareUrl}
                                quote={'Title or jo bhi aapko likhna ho'}
                                hashtag={'#portfolio...'}
                                beforeOnClick={() => { }}
                            >
                                <WhatsappIcon size={50} round={true} />
                            </WhatsappShareButton>
                            <button
                                className="notButton"
                            >
                                <img src={SaveShareMixWhatsappIcon} alt="Close Button" style={{ width: '50px' }} />
                            </button>
                        </div>
                        {/* <div className="whatsapp-share-icon position-relative mx-1">
                            <WhatsappShareButton
                                url={shareUrl}
                                quote={''}
                                hashtag={''}
                                beforeOnClick={() => { }}
                            >
                                <WhatsappIcon size={50} round={true} />
                            </WhatsappShareButton>
                            <span>Share via Whatsapp</span>
                            <button className="notButton">
                                <img src={SaveShareMixWhatsappIcon} alt="Close Button" style={{ width: '50px' }} />
                            </button>
                        </div> */}
                    </div>
                )}
            </div>

            {/* Copied Modal */}
            <Modal
                show={copiedText}
                centered
                className="copied-modal"
                backdrop="static"
            >
                <Modal.Body>
                    <div className="p-3">
                        <h2 className="font-16 lh-16 m-0 fw-normal text-center">Link Copied!</h2>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

export { DescriptionEnjoyAndEarn, LegalForm, ShareToSocialPlatform }; 