import React, { useEffect, useState } from 'react'
import BASEURL from '../../Config/global';
import { ShareIcon, crossIcon, MinusIconEmpty, PauseButton, PlayButton } from '../../Assets/svg';
import axios from 'axios';
import MainLayout from '../../Layout/MainLayout';
import { clearAllSound, playAudio, playSound, checkSoundPlayerOrMixes, playMyMixes, playMyMixer, removeMix, playMyMixerObj } from '../../Store/Slices/SoundPlayerSlice';
import { useDispatch, useSelector } from 'react-redux';
import './style.css';
import { useNavigate } from 'react-router';
import ShareMixSave from '../../Components/ShareMixSave/ShareMixSave';
import { Modal } from 'react-bootstrap';
import { pauseMixer, playMixer, resetMixer } from '../../Store/Slices/MixerSlice';
import { ClearMyMix, PauseMixer, PauseMyMixer, PlayMixer, PlayMyMix, PlayMyMixId } from '../../Store/Slices/MyMixerSlice';
import { Howl, Howler } from "howler";
import { activeUser, userLogout } from '../../Store/Slices/APIs';

const SaveShareMix = () => {
    const dispatch = useDispatch();
    const Navigate = useNavigate();
    const [playlist, setplaylist] = useState([]);
    const [playFilteredlist, setplayFilteredlist] = useState([]);
    const [soundlist, setSoundlist] = useState([]);
    const [isShareMixSaveShow, setIsShareMixSaveShow] = useState(false);
    const [saveShareMixId, setSaveShareMixId] = useState(0);
    const [isShareMixSaveName, setIsShareMixSaveName] = useState('');

    const [deleteMixerModal, setDeleteMixerModal] = useState(false);
    const [deleteMixerId, setDeleteMixerId] = useState(false);
    const [succesModal, setSuccesModal] = useState(false);
    const [playing, setPlaying] = useState(null);
    const [playingIndex, setPlayingIndex] = useState(null);

    const [messageShow, setMessageShow] = useState(false);

    const sounds = useSelector((state) => state.soundPlayer.sounds);
    const audio = useSelector((state) => state.soundPlayer.audio);
    const isPlaying = useSelector((state) => state.mixer.play);
    // const PauseMyMixer = useSelector((state) => state.mymixer.pauseMyMixer);
    const PlayMyMixer = useSelector((state) => state.mymixer.playMyMixer);
    const checkSoundPlayerOrMixesState = useSelector((state) => state.soundPlayer.checkSoundPlayerOrMixesState);

    // 11/9/2023
    const PauseMix = useSelector((state) => state.mymixer.isMixespp);
    // const [playMix, setPlayMix] = useState(false);
    const [playMixSounds, setPlayMixSounds] = useState([]);
    const [playingMixSounds, setPlayingMixSounds] = useState([]);
    const [playingMixAudio, setPlayingMixAudio] = useState([]);
    const [playPauseMixerState, setPlayPauseMixerState] = useState(false);


    const [playingMixAudioHowl, setPlayingMixAudioHowl] = useState({});
    const [playingMixSoundsHowl, setPlayingMixSoundsHowl] = useState([]);
    const [playMix, setPlayMix] = useState(false);
    const [pauseMix, setPauseMix] = useState(false);
    const [currentSoundId, setCurrentSoundId] = useState(null);
    const [playlistIndex, setPlaylistIndex] = useState(null);
    const isClearMixes = useSelector((state) => state.soundPlayer.isClearMixes);
    const isSoundPlay = useSelector((state) => state.soundPlayer.isSoundPlay);
    const soundPlayer = useSelector((state) => state.soundPlayer.sounds);
    const audioPlayer = useSelector((state) => state.soundPlayer.audio);
    const musicPlayer = useSelector((state) => state.soundPlayer.music);
    const mixObj = useSelector((state) => state.soundPlayer.mixObj);


    // const [playingMixAudio, setPlayingMixAudio] = useState([]);
    // 11/9/2023

    useEffect(() => {
        dispatch(userLogout())
    }, [])

    useEffect(() => {
        const playlistData = async () => {
            try {
                const response = await axios.get(`${BASEURL}/api/sounds/sounds_mixture`);
                if (!response.data.error) {
                    console.log(response?.data?.data)
                    setplaylist(response?.data?.data);
                }
            } catch (error) {
                console.error(error);
            }
        }
        playlistData();
    }, []);

    // useEffect(() => {
    //     if (soundPlayer.length > 0 || audioPlayer.hasOwnProperty('audio') || musicPlayer.hasOwnProperty('audio')) {
    //         clearMixList();
    //         // setCurrentSoundId(null);
    //         console.log("in")
    //     }

    // }, [soundPlayer, audioPlayer, musicPlayer])

    useEffect(() => {
        let filteredData = []
        playlist.length > 0 && playlist.filter((item) => {
            if (item.name !== "00990066") {
                filteredData.push(item)
            }
        })
        const reverseArray = [...filteredData].reverse();
        setplayFilteredlist(reverseArray)
    }, [playlist])

    useEffect(() => {
        if (PauseMix) {
            setPlaying(null);
        } else {
            setPlaying(playingIndex)
        }
    }, [PauseMix])

    // useEffect(() => {
    //     if (isPlaying) {
    //         setPlaying(playlistIndex);
    //     } else {
    //         setPlaying(null);
    //     }
    // }, [isPlaying])

    // console.log(isClearMixes)
    // useEffect(() => {
    //     if (isClearMixes) {
    //         clearMixList();
    //         setCurrentSoundId(null);
    //     }
    // }, [isClearMixes])

    // useEffect(() => {
    //     if (isSoundPlay) {
    //         clearMixList();
    //         setCurrentSoundId(null);
    //     }
    // }, [isSoundPlay])

    // const playingMixer = (index, sound) => {
    //     if (playing !== index) {
    //         setPlaying(index);
    //         setPlaylistIndex(index);
    //         if (sound.id != currentSoundId) {
    //             dispatch(clearAllSound());
    //             dispatch(playMyMixer(sound.sounds_list));
    //             setCurrentSoundId(sound.id);
    //             dispatch(playMyMixes());
    //         } else {
    //             playMixList();
    //         }
    //     } else {
    //         setPlaying(null);
    //         pauseMixList();
    //     }
    // }

    // // const playingList = (MyMixesPlayingData) => {
    // //     const soundLists = [];
    // //     const soundsData = [];
    // //     let audioData = {};
    // //     MyMixesPlayingData?.length > 0 && MyMixesPlayingData?.forEach((item, index) => {
    // //         dispatch(playMyMixer(item));
    // //         // if (item.type === "sounds") {
    // //         //     const howl = new Howl({
    // //         //         src: [BASEURL + item?.sounds_detail[0].ios_sound],
    // //         //         loop: true,
    // //         //         autoplay: true,
    // //         //         webAudio: true, // Use Web Audio API if supported
    // //         //         html5: true, // Use HTML5 audio if supported
    // //         //         autoUnlock: true,
    // //         //         preload: true,
    // //         //         volume: 0.5,
    // //         //         autoSuspend: false,
    // //         //         onload: function () {
    // //         //             console.log("loaded");
    // //         //         },
    // //         //     });
    // //         //     const patch = new Howl({
    // //         //         src: [BASEURL + item?.sounds_detail[0].ios_patch],
    // //         //         loop: true,
    // //         //         autoplay: true,
    // //         //         webAudio: true, // Use Web Audio API if supported
    // //         //         html5: true, // Use HTML5 audio if supported
    // //         //         autoUnlock: true,
    // //         //         preload: true,
    // //         //         volume: 0.5,
    // //         //         autoSuspend: false,
    // //         //         onload: function () {
    // //         //             console.log("loaded");
    // //         //         },
    // //         //         onend: function () {
    // //         //             console.log("End Patch");
    // //         //         },
    // //         //     });
    // //         //     soundLists.push({ howl, patch });
    // //         //     soundsData.push(item?.sounds_detail[0]);
    // //         // } else {
    // //         //     const howl = new Howl({
    // //         //         src: [BASEURL + item?.sounds_detail[0].audio],
    // //         //         loop: false,
    // //         //         autoplay: true,
    // //         //         webAudio: true, // Use Web Audio API if supported
    // //         //         html5: true, // Use HTML5 audio if supported
    // //         //         autoUnlock: true,
    // //         //         preload: true,
    // //         //         volume: 0.5,
    // //         //         autoSuspend: false,
    // //         //         onload: function () {
    // //         //             console.log("loaded");
    // //         //         },
    // //         //     });
    // //         //     setPlayingMixAudioHowl(howl);
    // //         //     audioData = item?.sounds_detail[0];
    // //         // }
    // //     });
    // //     setPlayingMixSoundsHowl(soundLists);
    // //     if (soundsData.length > 0) {
    // //         soundsData.forEach((item, index) => {
    // //             dispatch(playSound(item))
    // //             dispatch(playMyMixes());
    // //             dispatch(playMixer());
    // //         });
    // //     }
    // //     if (audioData.hasOwnProperty('audio')) {
    // //         dispatch(playAudio(audioData));
    // //         dispatch(playMyMixes());
    // //         dispatch(playMixer());
    // //     }
    // // }

    // const pauseMixList = () => {
    //     if (playingMixAudioHowl.hasOwnProperty('_duration')) {
    //         playingMixAudioHowl.pause();
    //     }
    //     playingMixSoundsHowl.length > 0 && playingMixSoundsHowl.forEach((item, index) => {
    //         item.howl.pause();
    //         item.patch.pause();
    //     });
    //     dispatch(pauseMixer());
    //     // console.log(playingMixAudioHowl);
    //     // console.log(playingMixAudioHowl.seek());
    //     // console.log(playingMixSoundsHowl);
    // }

    // const playMixList = () => {
    //     // console.log(playingMixAudioHowl);
    //     if (playingMixAudioHowl.hasOwnProperty('_duration')) {
    //         console.log(playingMixAudioHowl.seek());
    //         playingMixAudioHowl.play();
    //     }
    //     playingMixSoundsHowl.length > 0 && playingMixSoundsHowl.forEach((item, index) => {
    //         item.howl.play();
    //         item.patch.play();
    //     });
    //     dispatch(playMixer());
    //     // console.log(playingMixAudioHowl.seek());
    //     // console.log(playingMixSoundsHowl);
    // }

    // const clearMixList = () => {
    //     console.log("++++")
    //     if (playingMixAudioHowl.hasOwnProperty('_duration')) {
    //         playingMixAudioHowl.unload();
    //     }
    //     playingMixSoundsHowl.length > 0 && playingMixSoundsHowl.forEach((item, index) => {
    //         item.howl.unload();
    //         item.patch.unload();
    //     });
    //     setPlayingMixAudioHowl({});
    //     setPlayingMixSoundsHowl([]);
    //     // console.log(playingMixSoundsHowl);
    // }

    const pauseMyMixer = (index, sound) => {
        dispatch(pauseMixer());
    }

    const playingMixer = (index, sound) => {
        dispatch(activeUser());
        dispatch(playMyMixer(sound.sounds_list));
        dispatch(playMyMixerObj(sound));
        setTimeout(() => {
            dispatch(playMixer());
        }, 500);
    }

    const deleteShareMixSaveLink = (id) => {
        // console.log(playFilteredlist)
        let filteredData = [];
        playFilteredlist.length > 0 && playFilteredlist.filter((item) => {
            if (item.id !== id) {
                filteredData.push(item);
            }
        })

        setplayFilteredlist(filteredData);
        setSuccesModal(true);
        setDeleteMixerModal(false);


        //delete api call
        const deletePlaylist = async () => {
            try {
                const response = await axios.delete(`${BASEURL}/api/sounds/sounds_mixture/${id}`);
                if (!response.data.error) {
                    setTimeout(() => {
                        setSuccesModal(false);
                    }, 500);
                }
            } catch (error) {
                console.error(error);
            }
        }
        deletePlaylist();
    }

    const saveShareMixList = (sound) => {
        setSaveShareMixId(sound.id);
        setIsShareMixSaveName(sound.name);
        setSoundlist(sound.sounds_list);
        setIsShareMixSaveShow(true);
        document.body.classList.add('overflow-hidden');
    }

    const closeWindow = () => {
        document.body.classList.remove('overflow-hidden');
        setIsShareMixSaveShow(false);
    }

    return (
        <>
            <MainLayout>
                <div className='playlist-layout'>
                    <div className="col-12">
                        <h1 className="pageTitle my-sm-3 mt-3 mb-0 pb-3">My Mixes</h1>
                    </div>
                    {/* <h4 className='my-sm-3 mt-3 mb-0 pb-3 favorite-txt'>My Mixes</h4> */}
                    {
                        playFilteredlist.length > 0 && playFilteredlist.map((sound, index) => {
                            return (
                                <div className='mixer-pallete mb-2 mb-sm-3 p-2 p-sm-2' key={index}>
                                    {
                                        (mixObj?.id === sound.id && isPlaying) ?
                                            <button className='btn p-2 mx-sm-3 me-sm-4' onClick={() => pauseMyMixer(index, sound)}>
                                                <img src={PauseButton} className='play-icon' />
                                            </button>
                                            :
                                            <button className='btn p-2 mx-sm-3 me-sm-4' onClick={() => { sound.sounds_list.length > 0 && playingMixer(index, sound) }}>
                                                <img src={PlayButton} className='play-icon' />
                                            </button>

                                    }
                                    <div className="mixer-gradient">
                                        {sound.sounds_list.length > 0 && sound.sounds_list.map((soundThmb, index) => {
                                            return index < 3 && <div className='mixerSoundThumbnail' key={index}>
                                                <img src={soundThmb?.tumbnail.startsWith('/media/assets') ? 'https://storage.googleapis.com/relax_dev/assets' + soundThmb?.tumbnail.split('/media/assets')[1] : soundThmb?.tumbnail} /></div>
                                        })}
                                    </div>
                                    <div className='flex-grow-1'>
                                        <h5 className='mb-0 ps-3 ps-sm-4 fw-light mixer-name'>{sound.name}</h5>
                                    </div>

                                    <div className='flex-shrink-0'>
                                        <button className='btn p-2' onClick={() => saveShareMixList(sound)}>
                                            <img src={ShareIcon} className='share-icon' />
                                        </button>
                                    </div>
                                    <div className='flex-shrink-0 ms-2 mx-sm-3'>
                                        <button className='btn p-2' onClick={() => { setDeleteMixerModal(true); setDeleteMixerId(sound.id) }}>
                                            <img src={MinusIconEmpty} className='heart-icon' />
                                        </button>
                                    </div>
                                    {/* <div className='flex-shrink-0'>
                                        <button className='btn p-2' onClick={() => playMixer(index)}>
                                            <img src={TrackedPlay} className='cross-icon' />
                                        </button>
                                    </div> */}
                                </div>
                            )
                        })
                    }
                </div>

                {/* Share Mixer Modal */}
                <Modal
                    show={isShareMixSaveShow}
                    centered
                    className="mixer-modal"
                    backdrop="static"
                >
                    <Modal.Body>
                        <ShareMixSave handleClick={closeWindow} soundlist={soundlist} isSaveList={true} id={saveShareMixId} shareMixName={isShareMixSaveName} />
                    </Modal.Body>
                </Modal>

                {/* delete modal */}
                <Modal
                    show={deleteMixerModal}
                    centered
                    className="soundModal"
                    backdrop="static"
                >
                    <Modal.Body>
                        <div className="text-end">
                            <button
                                className="closeButton notButton ms-auto"
                                onClick={() => setDeleteMixerModal(false)}
                            >
                                <img src={crossIcon} alt="" />
                            </button>
                        </div>
                        <div className="customModalContent my-5">
                            <h2 className="font-16 lh-16 fw-normal mb-5 text-center">Are you sure you want to delete this Mix?</h2>
                            <div className="mt-4 d-flex align-items-center justify-content-center">
                                <button style={{ backgroundColor: '#ffffff1f' }} className='mx-3 px-5 py-2 fs-6 fw-light text-white text-capitalize btn rounded-pill d-flex align-items-center justify-content-center flex-shrink-0' onClick={() => deleteShareMixSaveLink(deleteMixerId)}>
                                    <span className=''>Yes</span>
                                </button>
                                <button style={{ backgroundColor: '#ffffff1f' }} className='mx-3 px-5 py-2 fs-6 fw-light text-white text-capitalize btn rounded-pill d-flex align-items-center justify-content-center flex-shrink-0' onClick={() => setDeleteMixerModal(false)}>
                                    <span className=''>No</span>
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* success modal */}
                <Modal
                    show={succesModal}
                    centered
                    className="success-modal"
                    backdrop="static"
                >
                    <Modal.Body>
                        <div className="p-5">
                            <h2 className="font-16 lh-16 my-5 fw-normal text-center">Mix Deleted</h2>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* success modal */}
                <Modal
                    show={messageShow}
                    centered
                    className="success-modal"
                    backdrop="static"
                >
                    <Modal.Body>
                        <div className="px-3 py-2 flex-grow-1 w-100">
                            <div className="text-end">
                                <button
                                    className="closeButton notButton ms-auto"
                                    onClick={() => setMessageShow(false)}
                                >
                                    <img src={crossIcon} alt="" />
                                </button>
                            </div>
                            <h2 className="font-16 lh-16 my-5 fw-normal text-center mt-5 pt-4">This save mix is no longer exist, <br /> please create a new one</h2>
                        </div>
                    </Modal.Body>
                </Modal>
            </MainLayout>
        </>
    )
}

export default SaveShareMix;