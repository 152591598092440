import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { PostAPI, localStorageData } from '../../Api/ApiConfig';
import CustomInput from '../../Components/CustomInput';
import CustomButton from '../../Components/CustomButton';
import { setAccessToken } from '../../Util/authHeader';
import { setUserDetails } from '../../Store/Slices/UserSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

const VerifyModal = ({ coupon, verifiedCoupon }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [emailConfig, setEmailConfig] = useState({ error: false, error_msg: "", isShow: true, code: '', loading: false });

    const confirmEmail = async (e) => {
        e.preventDefault();
        setEmailConfig({ ...emailConfig, 'loading': true });
        const user = await localStorageData();
        const data = new FormData();
        data.append("email", user.email);
        data.append("code", emailConfig.code);
        const res = await PostAPI('/api/user/verify_email/', data);
        setEmailConfig({ ...emailConfig, 'loading': false });
        if (!res.data.error) {
            if (coupon === "coupon") {
                verifiedCoupon();
                setEmailConfig({ ...emailConfig, 'isShow': false });
            } else {
                localStorage.setItem("user", JSON.stringify(res?.data?.data[0]));
                dispatch(setUserDetails(res.data.data[0]));
                if (res.data.data[0].status === "lead") {
                    navigate("/subscription");
                } else {
                    if (res.data.data[0].token) {
                        navigate("/download-app");
                    }
                }
                setEmailConfig({ ...emailConfig, 'isShow': false });
            }
        }
    }

    return (
        <Modal
            show={emailConfig.isShow}
            centered
            className="email-confirm-modal"
            backdrop="static"
        >
            <Modal.Body>
                <form className="w-100 px-4 py-5" >
                    <div className="row">
                        <div className="col-12">
                            <p className="lightColor text-center font-16 lh-16 whiteColor fw-bold">
                                Please check your email <br/> for the verification code.
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 customiseModal">
                            <CustomInput
                                placeholder="Enter Code"
                                required
                                id="code"
                                type="text"
                                labelClass="mainLabel"
                                inputClass="mainInput"
                                onChange={(event) => { setEmailConfig({ ...emailConfig, 'code': event.target.value }) }}
                            />
                        </div>
                    </div>
                    {emailConfig.error && (
                        <div className="row">
                            <div className="col-12">
                                <p className="smallText lightColor text-center">{emailConfig.error_msg}</p>
                            </div>
                        </div>
                    )}
                    <div className="row mt-3 text-center">
                        <div className="col-12">
                            {
                                emailConfig.loading ? (
                                    <button className="primaryButton customButton">
                                        <div className="spinner-border spinner-border-sm text-dark" role="status"></div>
                                    </button>
                                )
                                    : (
                                        <CustomButton
                                            variant="primaryButton"
                                            text="submit"
                                            onClick={confirmEmail}
                                        />
                                    )
                            }
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default VerifyModal